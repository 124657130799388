<template>
  <div class="main primary_color">
    <!-- nav -->
    <my-nav
      :current="current"
      @onHandleShow="handleShow"
    ></my-nav>

    <!-- 新闻资讯 -->
    <son-banner :title="title" :enTitle="enTitle" :current="current"></son-banner>

    <!-- 新闻内容 -->
    <div class="detailBox column align_center">
      <div class="column align_center">
        <div class="mt100 ft36">{{ info.title }}</div>
        <div class="mt30 ft24 info_color">发布时间：{{ info.inputtime }}</div>
        <div class="newsText mt60 ft24 lh36" style="width: 100%;"></div>
      </div>
    </div>

    <!-- other -->
    <my-other :current="current"></my-other>

    <!-- footer -->
    <my-footer :current="current" @onScrollTo="scroll"></my-footer>
  </div>
</template>

<script>
import { getNewsDetail } from '@/api/app'
export default {
  data() {
    return {
      info:{},
      id:0,
      title:'新闻资讯',
      enTitle:'BRAND NEWS',
      current: 3,
    }
  },
  mounted() {
    this.id = this.$route.query.id
    this.fetchNewsDetail()
  },
  methods: {
    handleShow(index,id) {
      this.current = index
      if (index == 0) {
        this.$router.push({
          path: "/",
        })
      }
      if (index == 1) {
        this.$router.push({
          path: "/allline",
          query:{
            index:id
          }
        })
      }
      if (index == 2) {
        this.$router.push({
          path: "/newmedia",
          query:{
            index:id
          }
        })
      }
      if (index == 3) {
        this.$router.push({
          path: "/news",
        })
      }
      if (index == 4) {
        this.$router.push({
          path: "/aboutus",
        })
      }
      this.handelUpdate()
      if(id){
        setTimeout(()=>{
          var el = document.getElementById(id)
          this.$nextTick(function () {
            window.scrollTo({
              behavior: "smooth",
              top: el.offsetTop + -88,
            })
          })
        },100)
      }else{
        return
      }
    },
    handelUpdate() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      })
    },
    scroll({id, offset, num}) {
      if (num == -1) {
        offset = offset || -10
        var el = document.getElementById(id)
        this.$nextTick(function () {
          window.scrollTo({
            behavior: "smooth",
            top: el.offsetTop + offset,
          })
        })
      } else {
        this.handleShow(num,id)
      }
    },
    async fetchNewsDetail(){
      let {data} = await getNewsDetail({
        id:this.id
      })
      console.log('getNewsDetail',data);
      this.info = data.data.val

      let el = document.querySelector('.newsText')
      let w = window.screen.width;
      console.log(w);
      if(w<=750){
        console.log(1);
        let c = this.info.content;
        c = c.replaceAll('font-size: 15px','font-size: 6px');
        c = c.replaceAll('font-size:15px','font-size: 6px');
        c = c.replaceAll('font-size: 16px','font-size: 6px');
        c = c.replaceAll('font-size:16px','font-size: 6px');
        c = c.replaceAll('margin-bottom: 26px;','margin-bottom: 4px;');
        c = c.replaceAll('margin-bottom:26px;','margin-bottom: 4px;');
        c = c.replaceAll('margin-top: 14px;','margin-top: 4px;');
        c = c.replaceAll('margin-top:14px;','margin-top: 4px;');
        c = c.replaceAll('<img src',`<img style='width:100%' src`);
        el.innerHTML = c;
      }else{
        console.log(2);
        el.innerHTML = this.info.content;
      }
    }
  },
}
</script>

<style scoped lang="scss">
.main{
  width: 100%;
  min-height: 100%;
  padding: 0px;
  margin: 0px;
  background-color: #fff;
  position: relative;
  overflow-x: hidden;
  .lh36{line-height: 36px;}
  .detailBox{
    width: 100vw;
    padding-bottom: 88px;
    background-color: #fff;
    >div{
      width: 62.5%;
      height: 100%;
    }
  }
  @media screen and (max-width: 750px) {
    .lh36{
      line-height: 12px;
    }
    .detailBox{
      padding-bottom: 4px;
    }
  }
}

::v-deep .el-pagination{
  font-size: 24px !important;
}
::v-deep .el-pager li{
  width: 50px;
  height: 50px;
  line-height: 50px;
}
::v-deep .el-pagination.is-background .el-pager li {
  color: #2E4C89 !important;
  font-size: 24px !important;
  margin: 0 15px;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #E9E9E9;
}
::v-deep .el-pagination.is-background .el-pager li:hover {
  color: #2E4C89 !important;
}
::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #2E4C89 !important;
    color: #FFFFFF !important;
}
::v-deep .el-pagination.is-background .btn-prev{
  background-color: transparent;
}
::v-deep .btn-prev:before{
    content: "上一页";
    color: #2E4C89;
    font-size: 24px !important;
    height: 50px;
    line-height: 50px;
    background-color: transparent;
}
::v-deep .btn-next:before{
    content: "下一页";
    color: #2E4C89;
    font-size: 24px !important;
    height: 50px;
    line-height: 50px;
}
::v-deep .el-pagination.is-background .btn-next{
  background-color: transparent;
}

::v-deep .el-pagination .btn-next .el-icon{
  display: none !important;
}
</style>
