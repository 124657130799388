<template>
  <div class="footerBox bg_bottom white_color ft16">
    <div class="footer row justify_sb">
      <div class="row">
        <ul
          class="column"
          v-for="(item, index) in list"
          :key="item.id"
          @click="scrollTo(index, $event)"
        >
          <li class="input_color ft18 cursor">{{ item.catname }}</li>
          <li class="cursor" v-for="tem in item.child" :key="tem.id">{{ tem.catname }}</li>
          <!-- <li>品牌推广方案</li>
            <li>品牌推广引流</li>
            <li>推广流程</li>
            <li>团队优势</li>
            <li>点击咨询</li> -->
        </ul>
        <!-- <ul class="column" @click="scrollTo(1,$event)">
            <li class="input_color ft18">全网营销</li>
            <li>信息流推广</li>
            <li>软文类推广</li>
            <li>品牌类推广</li>
          </ul>
          <ul class="column" @click="scrollTo(2,$event)">
            <li class="input_color ft18">新媒体营销</li>
            <li>快手营销</li>
            <li>抖音营销</li>
            <li>小红书营销</li>
            <li>微信营销</li>
            <li>微博营销</li>
            <li>全网营销</li>
          </ul>
          <ul class="column" @click="scrollTo(3,$event)">
            <li class="input_color ft18">新闻资讯</li>
            <li>媒体报道</li>
            <li>新媒体相关知识</li>
          </ul>
          <ul class="column" @click="scrollTo(4,$event)">
            <li class="input_color ft18">关于我们</li>
            <li>公司介绍</li>
            <li>联系我们</li>
          </ul> -->
      </div>
      <div class="column flex_center">
        <img class="logoimg" :src="logo" alt="" />
        <!-- <img :src="logo" alt="" style="width: 105px; height: 40px" />
        <img
          class="mt20"
          :src="erweima"
          alt=""
          style="width: 140px; height: 140px"
        />
        <div class="mt20">联系官方客服</div> -->
      </div>
    </div>
    <div class="ft12 mt30 input_color row flex_center">
      <!-- {{ icp }} -->
      <a href="https://beian.miit.gov.cn" target="_blank">{{ icp }}</a>
    </div>
  </div>
</template>
<script>
import {bottomNav, otherVal} from "@/api/app"

export default {
  data() {
    return {
      list: [],
      logo: "",
      erweima: "",
      icp: "",
    }
  },
  props: {
    current: Number,
  },
  mounted() {
    this.fetchBottomNav()
    this.fetchOtherVal()
  },
  methods: {
    scrollTo(num, e) {
      let data = {}
      let text = e.target.textContent
      console.log(text, num, this.current)
      if (num == this.current) {
        // 本页面滚动
        if (text == "首页") {
          data.num = 0
        } else if (text == "全网营销") {
          data.num = 1
        } else if (text == "新媒体营销") {
          data.num = 2
        } else if (text == "新闻资讯") {
          data.num = 3
        } else if (text == "关于我们") {
          data.num = 4
        } else {
          data.num = -1
          // num=0
          if (text == "联动引流") {
            data.id = "liandong"
          }
          if (text == "品牌推广方案") {
            data.id = "fangan"
          }
          if (text == "品牌推广引流") {
            data.id = "yinliu"
          }
          if (text == "推广流程") {
            data.id = "liucheng"
          }
          if (text == "团队优势") {
            data.id = "youshi"
          }
          if (text == "点击咨询") {
            data.id = "zixun"
          }
          // num=1
          if (text == "信息流推广") {
            data.id = "5"
          }
          if (text == "软文类推广") {
            data.id = "6"
          }
          if (text == "品牌类推广") {
            data.id = "7"
          }
          // num=2
          if (text == "快手营销") {
            data.id = "8"
          }
          if (text == "抖音营销") {
            data.id = "9"
          }
          if (text == "小红书营销") {
            data.id = "10"
          }
          if (text == "微信营销") {
            data.id = "11"
          }
          if (text == "微博营销") {
            data.id = "12"
          }
          if (text == "全网营销") {
            data.id = "13"
          }
          // num=3
          if (text == "媒体报道") {
            data.id = "meiti"
          }
          if (text == "新媒体相关知识") {
            data.id = "xinmeiti"
          }
          // num=4
          if (text == "公司介绍") {
            data.id = "jieshao"
          }
          if (text == "合作咨询") {
            data.id = "lianxi"
          }
        }
        data.offset = -88
      } else {
        // 跳转路由
        data.num = num
        data.offset = -88
        // num=0
        if (text == "联动引流") {
            data.id = "liandong"
          }
          if (text == "品牌推广方案") {
            data.id = "fangan"
          }
          if (text == "品牌推广引流") {
            data.id = "yinliu"
          }
          if (text == "推广流程") {
            data.id = "liucheng"
          }
          if (text == "团队优势") {
            data.id = "youshi"
          }
          if (text == "点击咨询") {
            data.id = "zixun"
          }
          // num=1
          if (text == "信息流推广") {
            data.id = "5"
          }
          if (text == "软文类推广") {
            data.id = "6"
          }
          if (text == "品牌类推广") {
            data.id = "7"
          }
          // num=2
          if (text == "快手营销") {
            data.id = "8"
          }
          if (text == "抖音营销") {
            data.id = "9"
          }
          if (text == "小红书营销") {
            data.id = "10"
          }
          if (text == "微信营销") {
            data.id = "11"
          }
          if (text == "微博营销") {
            data.id = "12"
          }
          if (text == "全网营销") {
            data.id = "13"
          }
          // num=3
          if (text == "媒体报道") {
            data.id = "meiti"
          }
          if (text == "新媒体相关知识") {
            data.id = "xinmeiti"
          }
          // num=4
          if (text == "公司介绍") {
            data.id = "jieshao"
          }
          if (text == "合作咨询") {
            data.id = "lianxi"
          }
      }
      console.log("data", data)
      this.$emit("onScrollTo", data)
    },
    async fetchOtherVal() {
      let {data} = await otherVal()
      this.logo = data.data.logo
      this.erweima = data.data.er_img
      this.icp = data.data.icp
    },
    async fetchBottomNav() {
      let {data} = await bottomNav()
      this.list = data.data.bottom_nav
    },
  },
}
</script>
<style lang="scss">
.footerBox {
  width: 100vw;
  // height: 500px;
  padding: 80px 460px 65px;
  .footer {
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(244, 244, 244, 0.5);
    > div:first-of-type {
      ul {
        margin-right: 80px;
        li {
          margin-bottom: 20px;
        }
      }
    }
  }
  a{
      color: #fff;
    }
    a:link{
      color:#fff
    }
    a:visited{
      color: #fff;
    }
    a:hover{
      color: #fff;
    }
    a:active{
      color: #fff;
    }
}
@media screen and (min-width: 1800px) and (max-width: 2000px) {
  .footerBox {
    padding: 80px 360px 65px;
  }
}
@media screen and (min-width: 1650px) and (max-width: 1800px) {
  .footerBox {
    padding: 80px 200px 65px;
  }
}
@media screen and (min-width: 750px) and (max-width: 1650px) {
  .footerBox {
    padding: 80px 100px 65px;
  }
}
@media screen and (max-width: 750px){
  .footerBox {
    // min-height: 26.0417vw;
    padding: 4.1667vw 20px 3.3854vw;
    .footer {
      padding-bottom: 10px;
      > div:first-of-type {
        ul {
          margin-right: 4.1667vw;
          li {
            margin-bottom: 1.0417vw;
          }
        }
      }
      .logoimg {
        width: 8.3333vw;
        height: 3.2292vw;
      }
    }
  }
}
</style>
