<template>
  <div class="main primary_color">
    <!-- nav -->
    <my-nav
      :current="current"
      @onHandleShow="handleShow"
    ></my-nav>

    <!-- 新闻资讯 -->
    <son-banner :title="title" :enTitle="enTitle" :current="current"></son-banner>

    <!-- 媒体报道 -->
    <div id="meiti" class="news1 column align_center">
      <div class="">
        <div class="row flex_center mt100">
          <div class="row">
            <div class="line-E1E0E0"></div>
            <div class="linew50h3"></div>
          </div>
          <div class="bolder ft32 marginx20">媒体报道</div>
          <div class="row">
            <div class="linew50h3"></div>
            <div class="line-E1E0E0"></div>
          </div>
        </div>
        <div class="row justify_sb align_center mt60">
          <div class="prev cursor" :class="one==0?'noprev':''" @click="prevBtn"></div>
          <ul class="row justify_sb">
            <li class="column" v-for="(item) in list" :key="item.id" @click="to_detail(item.id)">
              <img :src="item.thumb" alt="">
              <div class="bg_w padding20">
                <div class="ft24 dis1">{{ item.title }}</div>
                <div class="ft18 mt15 info_color dis2 lh20">{{item.description}}</div>
              </div>
            </li>
          </ul>
          <div class="next cursor" :class="one==this.one_list.length - 3?'nonext':''" @click="nextBtn"></div>
        </div>
      </div>
    </div>

    <!-- 媒体相关知识 -->
    <div id="xinmeiti" class="news2 column align_center">
      <div class="">
        <div class="row flex_center mt100">
          <div class="row">
            <div class="line-E1E0E0"></div>
            <div class="linew50h3"></div>
          </div>
          <div class="bolder ft32 marginx20">媒体相关知识</div>
          <div class="row">
            <div class="linew50h3"></div>
            <div class="line-E1E0E0"></div>
          </div>
        </div>
        <div class="knowledge mt10">
          <ul class="column mt50">
            <li class="row justify_sb" v-for="(item) in two_list" :key="item.id">
              <img :src="item.thumb" alt="">
              <div class="row justify_sb">
                <div class="date bg_dark br4 column flex_center white_color">
                  <div class="ft32">{{ item.day }}</div>
                  <div class="ft20 mt8">{{ item.year_month }}</div>
                </div>
                <div class="newB column justify_sb align_end ml50" >
                  <div class="column align_start" style="width: 100%;">
                    <div class="ft24 dis2 lh44">{{ item.title }}</div>
                    <div class="info_color ft18 mt40 dis3 lh32">{{ item.description }}</div>
                  </div>
                  <div class="btn_p row flex_center cursor ft20" @click="to_detail(item.id)">查看详情</div>
                </div>
              </div>
            </li>
            <!-- <li class="row justify_sb mt50">
              <img src="../assets/img/news/Mask group.png" alt="">
              <div class="row">
                <div class="date bg_dark br4 column flex_center white_color">
                  <div class="ft32">08</div>
                  <div class="ft20 mt8">2023.09</div>
                </div>
                <div class="newB column justify_sb align_end ml50">
                  <div>
                    <div class="ft24" style="line-height: 44px;">新闻媒体报道新闻媒体报道新闻媒体报道新闻媒体报道新闻媒体报道</div>
                    <div class="info_color ft18 mt40" style="line-height: 32px;">新闻媒体报道内容新闻媒体报道内容新闻媒体报道内容新闻媒体报道内容新闻媒体报道内容新闻媒体报道内容新闻媒体</div>
                  </div>
                  <div class="btn_p row flex_center">查看详情</div>
                </div>
              </div>
            </li>
            <li class="row justify_sb mt50">
              <img src="../assets/img/news/Mask group.png" alt="">
              <div class="row">
                <div class="date bg_dark br4 column flex_center white_color">
                  <div class="ft32">08</div>
                  <div class="ft20 mt8">2023.09</div>
                </div>
                <div class="newB column justify_sb align_end ml50">
                  <div>
                    <div class="ft24" style="line-height: 44px;">新闻媒体报道新闻媒体报道新闻媒体报道新闻媒体报道新闻媒体报道</div>
                    <div class="info_color ft18 mt40" style="line-height: 32px;">新闻媒体报道内容新闻媒体报道内容新闻媒体报道内容新闻媒体报道内容新闻媒体报道内容新闻媒体报道内容新闻媒体</div>
                  </div>
                  <div class="btn_p row flex_center">查看详情</div>
                </div>
              </div>
            </li> -->
          </ul>
          <div class="row mt88" style="justify-content: flex-end;">
            <el-pagination hide-on-single-page background layout="prev, pager, next" :pager-count="5" :page-size="per_page" :total="total" @current-change="handleCurrentChange" @prev-click="handlePrev" @next-click="handleNext"></el-pagination>
          </div>
        </div>
      </div>
    </div>

    <!-- other -->
    <my-other :current="current"></my-other>

    <!-- footer -->
    <my-footer :current="current" @onScrollTo="scroll"></my-footer>
  </div>
</template>

<script>
import { getNews } from '@/api/app'
export default {
  data() {
    return {
      one:0,
      one_list:[],
      two_list:[],
      last_page:0,
      total:0,
      per_page:0,
      page:1,
      list:[
        // {
        //   img:require('../assets/img/news/Mask group.png'),
        //   title:'报道标题报道标题报道标题',
        //   text:'报道内容报道内容报道内容报道内容报道内容报道内容',
        // },
        // {
        //   img:require('../assets/img/news/Mask group(1).png'),
        //   title:'报道标题报道标题报道标题',
        //   text:'报道内容报道内容报道内容报道内容报道内容报道内容',
        // },
        // {
        //   img:require('../assets/img/news/Mask group(2).png'),
        //   title:'报道标题报道标题报道标题',
        //   text:'报道内容报道内容报道内容报道内容报道内容报道内容',
        // },
      ],
      title:'新闻资讯',
      enTitle:'BRAND NEWS',
      current: 3,
    }
  },
  mounted() {
    this.fetchNews()
  },
  methods: {
    to_detail(id){
      this.$router.push({
        path:'/newsdetail',
        query:{
          id:id
        }
      })
      setTimeout(()=>{
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        })
      },100)
    },
    prevBtn(){
      if(this.one_list.length>3){
        if(this.one<=0){
          this.one = 0;
          this.list = this.one_list.slice(0,3)
          return
        }else{
          this.one--;
          this.list = this.one_list.slice(this.one, this.one + 3)
          return
        }
      }else{
        return
      }
    },
    nextBtn(){
      if(this.one_list.length>3){
        if((this.one + 3)>=this.one_list.length){
          this.one = this.one_list.length - 3
          this.list = this.one_list.slice(this.one_list.length - 3)
          return
        }else{
          this.one++;
          this.list = this.one_list.slice(this.one, this.one + 3)
          return
        }
      }else{
        return
      }
    },
    handleCurrentChange(e){
      this.page = e;
      this.fetchNews()
      this.to_meiti()
    },
    handlePrev(){
      if(this.page<1){
        this.page = 1
      }else{
        this.page--;
      }
      this.fetchNews()
      this.to_meiti()
    },
    handleNext(){
      if(this.page>this.last_page){
        this.page = this.last_page
      }else{
        this.page++;
      }
      this.fetchNews()
      this.to_meiti()
    },
    to_meiti(){
      var el = document.getElementById("xinmeiti")
      this.$nextTick(function () {
        window.scrollTo({
          behavior: "smooth",
          top: el.offsetTop + -88,
        })
      })
    },
    handleShow(index,id) {
      this.current = index
      if (index == 0) {
        this.$router.push({
          path: "/",
        })
      }
      if (index == 1) {
        this.$router.push({
          path: "/allline",
          query:{
            index:id
          }
        })
      }
      if (index == 2) {
        this.$router.push({
          path: "/newmedia",
          query:{
            index:id
          }
        })
      }
      if (index == 4) {
        this.$router.push({
          path: "/aboutus",
        })
      }
      this.handelUpdate()
      if(id){
        setTimeout(()=>{
          var el = document.getElementById(id)
          this.$nextTick(function () {
            window.scrollTo({
              behavior: "smooth",
              top: el.offsetTop + -88,
            })
          })
        },100)
      }else{
        return
      }
    },
    handelUpdate() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      })
    },
    scroll({id, offset, num}) {
      if (num == -1) {
        offset = offset || -10
        var el = document.getElementById(id)
        this.$nextTick(function () {
          window.scrollTo({
            behavior: "smooth",
            top: el.offsetTop + offset,
          })
        })
      } else {
        this.handleShow(num,id)
      }
    },
    async fetchNews(){
      let {data} = await getNews({
        page:this.page
      })
      console.log('getNews',data.data);
      this.one_list = data.data.one_list
      this.list = this.one_list.slice(0,3)
      this.two_list = data.data.two_list.data
      this.total = data.data.two_list.total
      this.per_page = data.data.two_list.per_page
      this.last_page = data.data.two_list.last_page
    }
  },
}
</script>

<style scoped lang="scss">
.main{
  width: 100%;
  min-height: 100%;
  padding: 0px;
  margin: 0px;
  background-color: #fff;
  position: relative;
  overflow-x: hidden;
  .news1{
    width: 100vw;
    height: 850px;
    background-color: #F4F6F9;
    .lh20{
      line-height: 20px;
    }
    >div{
      width: 75%;
      height: 100%;
      >div{
        width: 100%;
        .prev{
          width: 60px;
          height: 60px;
          background: url('http://www.ruizhancq.com/common/news/icon_zuojiantou_lan_60.png') no-repeat;
          background-size: 100% 100%;
        }
        .noprev{
          background: url('http://www.ruizhancq.com/common/news/icon_zuojiantou_60.png') no-repeat;
          background-size: 100% 100%;
        }
        .next{
          width: 60px;
          height: 60px;
          background: url('http://www.ruizhancq.com/common/news/icon_youjiantou_60.png') no-repeat;
          background-size: 100% 100%;
        }
        .nonext{
          background: url('http://www.ruizhancq.com/common/news/icon_youjiantou_hui_60.png') no-repeat;
          background-size: 100% 100%;
        }
        >ul{
          width: 62.5vw;
          >li{
            width: calc( (62.5vw - 40px) / 3 );
            >img{
              width: calc( (62.5vw - 40px) / 3 );
              height: calc( (62.5vw - 40px) / 3 - 100px);
            }
            >div{
              width: 100%;
            }
          }
        }
      }
    }
  }
  @media screen and (min-width: 1800px) and (max-width: 2000px) {
    .news1{
      height: 750px;
    }
  }
  @media screen and (min-width: 1500px) and (max-width: 1800px) {
    .news1{
      height: 650px;
    }
  }
  @media screen and (min-width: 1100px) and (max-width: 1500px) {
    .news1{
      height: 550px;
      >div{
        >div{
          .prev,.next{
            width: 40px;
            height: 40px;
          }
        }
      }
      .ft24{
        font-size: 20px;
      }
      .ft18{
        font-size: 14px;
      }
    }
  }

  @media screen and (min-width: 750px) and (max-width: 1100px) {
    .news1{
      height: 450px;
      >div{
        >div{
          .prev,.next{
            width: 40px;
            height: 40px;
          }
        }
      }
      .ft24{
        font-size: 16px;
      }
      .ft18{
        font-size: 12px;
      }
    }
  }
  @media screen and (max-width: 750px){
    .news1{
      height: 44.2708vw;
      .lh20{line-height: 10px;}
      >div{
        >div{
          .prev{
            width: 3.125vw;
            height: 3.125vw;
          }
          .next{
            width: 3.125vw;
            height: 3.125vw;
          }
          >ul{
            >li{
              width: calc( (62.5vw - 2.0833vw) / 3 );
              >img{
                width: calc( (62.5vw - 2.0833vw) / 3 );
                height: calc( (62.5vw - 2.0833vw) / 3 - 5.2083vw);
              }
            }
          }
        }
      }
      .ft24{
        font-size: 8px;
      }
      .ft18{
        font-size: 6px;
      }
    }
  }

  .news2{
    width: 100vw;
    padding-bottom: 88px;
    background-color: #fff;
    >div{
      width: 62.5%;
      height: 100%;
      >.knowledge{
        width: 100%;
        >ul{
          width: 100%;
          >li{
            width: 100%;
            padding: 50px 0px;
            border-bottom: 1px solid #E9E9E9;
            >img{
              width: 41.7%;
              // height: 422px;
              height: 17vw;
            }
            >div{
              width: 52.5%;
              height: 17vw;
              >.date{
                width: 100px;
                height: 100px;
              }
              >.newB{
                width: calc(100% - 150px);
                position: relative;
                .mt40{
                  margin-top: 2vw;
                }
                .lh44{line-height: 44px;}
                .lh32{line-height: 32px;}
              }
            }
            @media screen and (min-width: 1650px) and (max-width: 2000px) {
              >img{
                // height: 322px;
                height: 17vw;
              }
              >div{
                // height: 322px;
                height: 17vw;
              }
              .mt40{
                margin-top: 2vw;
              }
            }
            @media screen and (min-width: 1100px) and (max-width: 1650px) {
              >img{
                // height: 272px;
                height: 17vw;
              }
              >div{
                // height: 272px;
                height: 17vw;
                >.newB{
                  .mt40{
                    margin-top: 2vw;
                  }
                  >div{
                    >div:first-of-type{
                      font-size: 20px;
                      line-height: 32px !important;
                    }
                    >div:last-of-type{
                      font-size: 14px;
                      line-height: 28px !important;
                      margin-top: 20px;
                    }
                  }
                }
              }
            }
            @media screen and (min-width: 750px) and (max-width: 1100px) {
              >img{
                // height: 222px;
                height: 17vw;
              }
              >div{
                // height: 222px;
                height: 17vw;
                >.newB{
                  .mt40{
                    margin-top: 2vw;
                  }
                  >div{
                    >div:first-of-type{
                      font-size: 16px;
                      line-height: 32px !important;
                    }
                    >div:last-of-type{
                      font-size: 12px;
                      line-height: 28px !important;
                      margin-top: 20px;
                    }
                  }
                }
              }
            }
          }
          >li:first-of-type{
            border-top: 1px solid #E9E9E9;
          }
        }
      }
    }
  }
  @media screen and (max-width: 750px){
    .news2{
      padding-bottom: 4.5833vw;
      >div{
        >.knowledge{
          >ul{
            >li{
              padding: 2.6042vw 0px;
              >div{
                >.date{
                  width: 7.5vw;
                  height: 7.5vw;
                }
                >.newB{
                  width: calc(100% - 7.8125vw);
                  .lh44{line-height: 12px;}
                  .lh32{line-height: 10px;}
                }
              }
            }
          }
        }
      }
    }
  }
}

::v-deep .el-pagination{
  font-size: 24px !important;
}
::v-deep .el-pager li{
  width: 50px;
  height: 50px;
  line-height: 50px;
}
::v-deep .el-pagination.is-background .el-pager li {
  color: #2E4C89 !important;
  font-size: 24px !important;
  margin: 0 15px;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #E9E9E9;
}
::v-deep .el-pagination.is-background .el-pager li:hover {
  color: #2E4C89 !important;
}
::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #2E4C89 !important;
    color: #FFFFFF !important;
}
::v-deep .el-pagination.is-background .btn-prev{
  background-color: transparent;
}
::v-deep .btn-prev:before{
    content: "上一页";
    color: #2E4C89;
    font-size: 24px !important;
    height: 50px;
    line-height: 50px;
    background-color: transparent;
}
::v-deep .btn-next:before{
    content: "下一页";
    color: #2E4C89;
    font-size: 24px !important;
    height: 50px;
    line-height: 50px;
}
::v-deep .el-pagination.is-background .btn-next{
  background-color: transparent;
}

::v-deep .el-pagination .btn-next .el-icon{
  display: none !important;
}

@media screen and (max-width: 750px) {
  ::v-deep .el-pagination{
    font-size: 8px !important;
  }
  ::v-deep .el-pager li{
    width: 30px;
    height: 30px;
    line-height: 30px;
  }
  ::v-deep .el-pagination.is-background .el-pager li {
    font-size: 8px !important;
    margin: 0 0.78125vw;
    border-radius: 2px;
  }
  ::v-deep .btn-prev:before{
      content: "上一页";
      font-size: 8px !important;
      height: 3.125vw;
      line-height: 30px;
  }
  ::v-deep .btn-next:before{
      content: "下一页";
      font-size: 8px !important;
      height: 3.125vw;
      line-height: 30px;
  }
}

</style>
